import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import Customers from "components/ui/extended/Customers";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import VideoLiveChatBox from "components/ui/extended/VideoLiveChatBox";
import CustomerServiceStories from "components/ui/extended/CustomerServiceStories";
import MarketingAutomationStories from "components/ui/extended/MarketingAutomationStories";
import CustomerSatisfactionStories from "components/ui/extended/CustomerSatisfactionStories";
import FullWidthVideo from "components/ui/base/FullWidthVideo";
import PlainHeader from "components/ui/base/PlainHeader";
import TestimonialWithImageYari from "components/ui/extended/TestimonialWithImageYari";

interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: IndexPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.customer-service-solution.seo.title")}
        description={t("pages.customer-service-solution.seo.description")}
        image={{
          relativePath: "meta/customer-service-software.jpg",
          alt: "Customer support Software to make your customer life easier",
        }}
      />

      <PlainHeader
        title={t("pages.o.github.title")}
        description={t("pages.o.github.description")}
        titleMaxWidth={960}
        descriptionMaxWidth={700}
        ctaRegister={{ show: true }}
        image={{
          relativePath: "pages/index/Customer-Support-hero.jpg",
          alt: "Customerly Customer Service Software Inboxes and Live Chat",
        }}
      />

      <Customers />

      <CustomerServiceStories />

      <VideoLiveChatBox />

      <MarketingAutomationStories />

      <FullWidthVideo
        title={t("pages.o.github.email.title")}
        description={t("pages.o.github.email.description")}
        video={{
          path: "pages/email-marketing/email-marketing-wide.mp4",
        }}
      />

      <CustomerSatisfactionStories />

      <RegisterForCustomerService />

      <TestimonialWithImageYari />
    </GlobalWrapper>
  );
};

export const indexPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
